import {
  AcademicCapIcon,
  ChartBarIcon,
  ChatAlt2Icon,
  ClipboardListIcon,
  CogIcon,
  DatabaseIcon,
  LightningBoltIcon,
  TerminalIcon,
  UserGroupIcon,
} from '@heroicons/react/outline';
import classNames from 'classnames';
import { Link, navigate } from 'gatsby';
import { Pricing } from '../components/Pricing';
import { StaticImage } from 'gatsby-plugin-image';
import * as React from 'react';
import { useRef } from 'react';
import { GlowingRing } from '../components/elements/landing/GlowingRing';
import { GlowingText } from '../components/elements/landing/GlowingText';
import { GradientText } from '../components/elements/landing/GradientText';
import { HighlightedText } from '../components/elements/landing/HighlightedText';
import { Testimonials } from '../components/Testimonials';
import ContributorsSection from '../components/Index/ContributorsSection';
import { CPIProjectCard } from '../components/Index/CPIProjectCard';
import { Feature } from '../components/Index/Feature';
import { ProblemsetsFeature } from '../components/Index/features/ProblemsetsFeature';
import { ProgressTrackingFeature } from '../components/Index/features/ProgressTrackingFeature';
import { ResourcesFeature } from '../components/Index/features/ResourcesFeature';
import {
  EasyFunCoding,
  Vercel,
  XCamp,
} from '../components/Index/sponsor-logos';
import TrustedBy from '../components/Index/TrustedBy';
import Layout from '../components/layout';
import SEO from '../components/seo';
import TopNavigationBar from '../components/TopNavigationBar/TopNavigationBar';
import UserDataContext from '../context/UserDataContext/UserDataContext';

const containerClasses = 'max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8';
const headerClasses =
  'text-4xl md:text-5xl 2xl:text-6xl font-black text-black dark:text-white';
const headerClassesNoText = 'text-4xl md:text-5xl 2xl:text-6xl font-black';
const subtextClasses =
  'text-lg md:text-xl 2xl:text-2xl font-medium max-w-4xl leading-relaxed text-gray-700 dark:text-gray-400';
const headerSubtextSpacerClasses = 'h-6 2xl:h-12';
const whiteButtonClassesBig =
  'text-xl bg-white px-6 py-3 md:px-8 md:py-4 rounded-lg font-medium text-gray-900 relative';
const whiteButtonClasses =
  'text-lg md:text-xl bg-white px-4 py-2 md:px-6 md:py-3 rounded-lg font-medium text-gray-900 relative';
const usacoTitleClasses =
  'md:text-center font-black tracking-tight text-5xl sm:text-6xl md:text-7xl 2xl:text-8xl';
const linkTextStyles =
  'text-blue-600 dark:text-blue-300 transition hover:text-purple-600 dark:hover:text-purple-300';

export default function IndexPage(): JSX.Element {
  const learnMoreRef = useRef<HTMLDivElement>();
  const { firebaseUser } = React.useContext(UserDataContext);
  return (
    <Layout>
      <SEO title={null} />

     <div className="dark bg-black">
        <TopNavigationBar transparent />
      </div>

      {/* Begin Hero */}
      <div className="relative isolate overflow-hidden pt-14">
        <img
          src="https://starcoder.org/assets/images/Cropped-Website-Background.jpg"
          alt=""
          className="brightness-75 absolute inset-0 -z-10 h-full w-full object-cover"
        />
          {/* <img className="brightness-50 absolute inset-0 -z-10 h-full w-full object-cover" src="../assets/background.jpg" alt="A dinosaur"/> */}

        <div
          className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-20 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>
      <div className="relative -mt-16 brightness-100">
        <div className="md:min-h-screen flex flex-col px-4 sm:px-6 lg:px-8">
          <div className="h-6 sm:h-12"></div>

          <div className="flex-1 flex flex-col justify-center object-center">
              <div className="h-20"></div>
                                        <div className="object-center	flex justify-center min-h-100"> <StaticImage src="../assets/codetree.png" className="object-scale-down object-center justify-center" class="w-1/4 min-h-100" alt="penguin"/></div>

            <div className="relative flex md:justify-center">

                <GlowingText className="md:text-center font-black tracking-tight text-5xl sm:text-6xl md:text-7xl 2xl:text-8xl text-white mt-4">
                Code2Grow
              </GlowingText>
            </div>

            <div className="h-6 sm:h-8"></div>

            <p
              className="
                md:text-center
                text-xl
                sm:text-2xl
                2xl:text-3xl
                font-medium
                leading-snug
                md:!leading-normal
                text-gray-300
              "
            >
              <GradientText>STEM Education </GradientText> from{' '}
              Coding to Robotics{' '}
              <br className="hidden md:block" />
              
            </p>

            <div className="h-8 sm:h-12"></div>

            <div className="flex md:justify-center">
              <GlowingRing>
                <Link
                  to="/dashboard"
                  className={classNames(whiteButtonClassesBig, 'inline-block')}
                >
                  Get Started
                </Link>
              </GlowingRing>
            </div>
          </div>

          <div className="h-16 sm:h-24"></div>

          <div className="flex md:justify-center md:text-xl text-gray-400">
            <a
              href="https://www.windtreeeducation.org/"
              className="inline-flex items-center space-x-3 md:space-x-4"
            >
              <div className="h-9 w-9">
                                    <StaticImage className="h-9 w-9" src="../assets/logo.png" alt="A dinosaur"/>

              </div>

              <span>Created by Windtree Education</span>
            </a>
          </div>
          <div className="h-4 sm:h-6 md:h-16"></div>
        </div>
        </div>
        </div>
      {/* End Hero */}

      {/* Learn Code2Grow. Efficiently. */}
      <div className="bg-white">
        <div className="h-12 sm:h-20 md:h-36 2xl:h-48"></div>

        <div className={containerClasses}>
          <h2 className={headerClasses}>
            Learn Robotics: <HighlightedText>The Easy Way.</HighlightedText>
          </h2>
          <div className={headerSubtextSpacerClasses}></div>
          <p className={subtextClasses}>
            Discover Code2Grow, an education website designed for convenience, offering <b className="text-black">easily digestible lessons</b> to empower users with a clear understanding of robotics concepts and applications.{' '}
          </p>

          <div className="h-12 md:h-20 2xl:h-36"></div>

          <Feature
            icon={ChatAlt2Icon}
            iconClasses="from-green-400 to-cyan-500"
            title="Visual aided coding for robotics"
            blobClasses="bg-green-200"
            feature={
              <div className="shadow-lg rounded-lg">
                <StaticImage
                  src="../assets/blockly1.png"
                  alt="USACO Forum Screenshot"
                  placeholder="blurred"
                  layout="constrained"
                  className="rounded-lg"
                  width={560}
                />
              </div>
            }
            featurePosition="left"
            fade="none"
          >
            Create robotics programs with intuitive graphical building blocks!
          </Feature>

          <div className="h-12 md:h-20 2xl:h-36"></div>

          <Feature
            icon={ChatAlt2Icon}
            iconClasses="from-green-400 to-cyan-500"
            title="Python programming"
            blobClasses="bg-green-200"
            feature={
              <div className="shadow-lg rounded-lg">
                <StaticImage
                  src="../assets/blockly2.png"
                  alt="USACO Forum Screenshot"
                  placeholder="blurred"
                  layout="constrained"
                  className="rounded-lg"
                  width={560}
                />
              </div>
            }
            featurePosition="right"
            fade="none"
          >
            Support both automatically generated Python programs or manual coding. 
          </Feature>

          <div className="h-12 md:h-20 2xl:h-36"></div>

          <Feature
            icon={ChatAlt2Icon}
            iconClasses="from-green-400 to-cyan-500"
            title="Download your program to your own robot!"
            blobClasses="bg-green-200"
            feature={
              <div className="shadow-lg rounded-lg">
                <StaticImage
                  src="../assets/robot2.png"
                  alt="USACO Forum Screenshot"
                  placeholder="blurred"
                  layout="constrained"
                  className="rounded-lg"
                  width={560}
                />
              </div>
            }
            featurePosition="left"
            fade="none"
          >
            Download your program to the windtree robot.
          </Feature>

          <div className="h-12 md:h-20 2xl:h-36"></div>

                    <Feature
            icon={ChatAlt2Icon}
            iconClasses="from-green-400 to-cyan-500"
            title="Control your robot with your program."
            blobClasses="bg-green-200"
            feature={
              <div className="shadow-lg rounded-lg">
                <StaticImage
                  src="../assets/robot1.png"
                  alt="USACO Forum Screenshot"
                  placeholder="blurred"
                  layout="constrained"
                  className="rounded-lg"
                  width={560}
                />
              </div>
            }
            featurePosition="right"
            fade="none"
          >
            Download your program to the windtree robot.
          </Feature>
         
        </div>
        <div className="h-16 md:h-20 2xl:h-36"></div>
      </div>
      {/* End Learn Code2Grow. Efficiently. */}

 
      <Pricing />
      <Testimonials />

      <div className="bg-white dark:bg-dark-surface">
        <div className="max-w-screen-xl mx-auto py-12 px-4">
          <p className="text-center text-base leading-6 text-gray-400 dark:text-dark-med-emphasis">
            &copy; 2023 Windtree Education.  
          </p>
        </div>
      </div>
    </Layout>
  );
}
