import clsx from 'clsx';
import * as React from 'react';
import { GlowingText } from '../components/elements/landing/GlowingText';
 
const headerClassesDark =
  'text-4xl md:text-5xl 2xl:text-6xl font-black text-white';

export function Pricing({
  className,
}: {
  className?: string;
  }): JSX.Element {
  return (
    <section
      id="videos"
      aria-label="Videos"
      className="bg-slate-900 bg-[#262c47] py-20 sm:py-32"
    >
      <div
      className={clsx('mx-auto max-w-7xl px-4 sm:px-6 lg:px-8', className)}>
        <div className="md:text-center">
          <h3 className="font-display text-2xl tracking-tight text-white sm:text-2xl">
            <span className="relative whitespace-nowrap text-white">
              <GlowingText className={headerClassesDark} extraGlow>
            Robotics Demonstration
          </GlowingText>
            </span>{' '}
          </h3>
                    <div className="h-4 2xl:h-12"></div>

        </div>

        <div className="aspect-w-16 aspect-h-9">
  <iframe src="https://player.vimeo.com/video/811406777?color=0c88dd&autoplay=1&muted=1&title=0&byline=0&portrait=0&badge=0" width="640" height="360" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
</div>
        
      </div>
    </section>
  )
}